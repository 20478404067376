import React, { useState } from "react";
import "./HeaderModuleDeCommande.css";
import CreateAcount from "../../Authentification/CreateAcount/CreateAcount";

const HeaderModuleDeCommande = () => {
  return (
    <div
      style={{
        minWidth: "800px",
        borderBottom: "1px solid #ccc",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        paddingLeft: "15vw", // Marge à gauche proportionnelle à la largeur de la fenêtre
        paddingRight: "15vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <img
        src="/img/KitchnClikLogo.png"
        style={{ maxWidth: "200px", padding: "15px" }}
      />
      <div
        onClick={() =>
          (window.location.href =
            "https://d1ocn6482cd.typeform.com/to/TxB5dJe2")
        }
        className="ButtonPasserEnLigne"
      >
        Passer mon resto en ligne
      </div>
      {/* <CreateAcount />*/}
    </div>
  );
};

export default HeaderModuleDeCommande;
