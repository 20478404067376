import React, { useEffect } from "react";
import Header from "./Header/Header";
import MainDashMarketingPage from "./MainDashMarketingPage/MainDashMarketingPage";

const CreationModule = () => {
  return (
    <>
      <Header />
      <div>
        <MainDashMarketingPage />
      </div>
    </>
  );
};

export default CreationModule;
