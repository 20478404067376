import React, { useState } from "react";
import { IoPencilSharp } from "react-icons/io5";

const AdresseInfo = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "25px",
        paddingBottom: "25px",
        borderBottom: "1px solid #ccc",
        paddingLeft: "15vw", // Marge à gauche proportionnelle à la largeur de la fenêtre
        paddingRight: "15vw",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          allignItems: "center",
        }}
      >
        <span style={{ fontSize: "26px", fontWeight: "bold" }}>
          Nom du restaurant
        </span>{" "}
        <span style={{ marginLeft: "10px", cursor: "pointer" }}>
          <IoPencilSharp size={18} /> modifier
        </span>
      </div>
      <div style={{ marginTop: "10px" }}>
        Adresse
        <span style={{ marginLeft: "10px", cursor: "pointer" }}>
          <IoPencilSharp size={18} /> modifier
        </span>
      </div>
      <div style={{ marginTop: "10px" }}>
        Heure de fermeture{" "}
        <span style={{ marginLeft: "10px", cursor: "pointer" }}>
          <IoPencilSharp size={18} /> modifier
        </span>
      </div>
    </div>
  );
};

export default AdresseInfo;
