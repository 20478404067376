import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button, CircularProgress } from "@material-ui/core";
import { MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";
import { generateTimeSlots } from "../../Data/firebaseInteractionMarketing";

import "./ModalAvecState.css";
import { to } from "react-spring";
import { set } from "date-fns";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "90%",
    maxHeight: "90vh",
    overflowY: "auto",
    border: "2px solid #000",
    borderRadius: "5px",
  },
  formContainer: {
    border: "1px solid #ccc",
    padding: "20px",
    borderRadius: "5px",
  },
}));

const ModalAvecState = ({
  open,
  handleClose,
  setSurPlaceOrEmporter,
  SurPlaceOrEmporter,
  setSelectedDay,
  setSelectedTime,
  selectedTime,
  selectedDay,
}) => {
  const classes = useStyles();
  const [ListeDays, setListeDays] = useState(null); // Ajoutez d'autres jours au besoin
  const [ListeTime, setListeTime] = useState(null);

  useEffect(() => {
    const SetData = async () => {
      const today = new Date();
      const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
      const AfterTomorrow = new Date(tomorrow.getTime() + 48 * 60 * 60 * 1000);
      const daysOfWeek = [
        "dimanche",
        "lundi",
        "mardi",
        "mercredi",
        "jeudi",
        "vendredi",
        "samedi",
      ];
      const months = [
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre",
      ];
      setListeDays([
        `${daysOfWeek[today.getDay()]} ${today.getDate()} ${
          months[today.getMonth()]
        } (aujourd'hui)`,
        `${daysOfWeek[tomorrow.getDay()]} ${tomorrow.getDate()} ${
          months[tomorrow.getMonth()]
        }`,
        `${daysOfWeek[AfterTomorrow.getDay()]} ${AfterTomorrow.getDate()} ${
          months[AfterTomorrow.getMonth()]
        }`,
      ]);
      setSelectedDay(
        `${daysOfWeek[today.getDay()]} ${today.getDate()} ${
          months[today.getMonth()]
        } (aujourd'hui)`
      );
      const ListeTime = generateTimeSlots();
      setListeTime(ListeTime);
      setSelectedTime(ListeTime[0]);
    };
    SetData();
  }, []);

  const handleInteract = async () => {};

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div className={classes.paper}>
          <h2 id="modal-title"></h2>

          <div
            className="Form"
            style={{
              border: "1px solid #ccc",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={
                    SurPlaceOrEmporter === true
                      ? {
                          paddingLeft: "50px",
                          paddingRight: "50px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          border: "1px solid purple",
                          borderRadius: "5px",
                          cursor: "pointer",
                          color: "white",
                          backgroundColor: "purple",
                          cursor: "pointer",
                          marginRight: "10px",
                        }
                      : {
                          paddingLeft: "50px",
                          paddingRight: "50px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          border: "1px solid purple",
                          borderRadius: "5px",
                          cursor: "pointer",
                          color: "purple",
                          backgroundColor: "white",
                          cursor: "pointer",
                          marginRight: "10px",
                        }
                  }
                  onClick={() => setSurPlaceOrEmporter(true)}
                >
                  Sur place
                </div>
                <div
                  style={
                    SurPlaceOrEmporter === false
                      ? {
                          paddingLeft: "50px",
                          paddingRight: "50px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          border: "1px solid purple",
                          borderRadius: "5px",
                          cursor: "pointer",
                          color: "white",
                          backgroundColor: "purple",
                          cursor: "pointer",
                          marginRight: "10px",
                        }
                      : {
                          paddingLeft: "50px",
                          paddingRight: "50px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          border: "1px solid purple",
                          borderRadius: "5px",
                          cursor: "pointer",
                          color: "purple",
                          backgroundColor: "white",
                          cursor: "pointer",
                          marginRight: "10px",
                        }
                  }
                  onClick={() => setSurPlaceOrEmporter(false)}
                >
                  À emporter
                </div>
              </div>
              <div>
                <div style={{ marginTop: "25px", marginBottom: "25px" }}>
                  Choix de l'horaire
                </div>
                {ListeDays && (
                  <FormControl
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel id="delivery-day-label">
                      Jour de livraison
                    </InputLabel>
                    <Select
                      labelId="delivery-day-label"
                      id="delivery-day-select"
                      value={selectedDay}
                      onChange={(e) => setSelectedDay(e.target.value)}
                    >
                      {ListeDays.map((day) => (
                        <MenuItem value={day}>{day}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {ListeTime && (
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="delivery-day-label">
                      Heure de livraison
                    </InputLabel>
                    <Select
                      labelId="delivery-day-label"
                      id="delivery-day-select"
                      value={selectedTime}
                      onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      {ListeTime.map((time) => (
                        <MenuItem value={time}>{time}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            color="secondary"
            style={{
              backgroundColor: "purple",
              width: "100%",
              marginTop: "20px",
            }}
            onClick={() => {
              handleClose();
            }}
          >
            Valider
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalAvecState;
