import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaShoppingCart } from "react-icons/fa";
import { PiForkKnifeFill } from "react-icons/pi";
import { IoPencilSharp } from "react-icons/io5";
import CircularProgress from "@material-ui/core/CircularProgress";
import ModalAvecState from "../../../ModalAvecState/ModalAvecState";
import TextField from "@material-ui/core/TextField";
import { MdOutlineDirectionsRun } from "react-icons/md";
import { CiCircleMinus } from "react-icons/ci";

import { FaCirclePlus } from "react-icons/fa6";

import "./CartDissplay.css";

const CartDissplay = ({ ProductInCart, ModuleID, setProductInCart }) => {
  console.log(ProductInCart);
  const [responseData, setResponseData] = useState(null);
  const [IsCheckOut, setIsCheckOut] = useState(false);

  const [OpenModal, setOpenModal] = useState(false);
  const [SurPlaceOrEmporter, setSurPlaceOrEmporter] = useState(false);

  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Remplacez 'YOUR_CLOUD_FUNCTION_URL' par l'URL de votre Cloud Function
  const HandleSendPaiement = async (ProductInCart) => {
    if (IsCheckOut || ProductInCart.length === 0) return;
    setIsCheckOut(true);
    const requestBody = {
      ProductInCart,
      userID: "YOUR_USER_ID",
      infoDelivery: JSON.stringify({
        selectedDay,
        selectedTime,
        SurPlaceOrEmporter,
      }),
      ModuleID,
      // Ajoutez d'autres clés/valeurs selon vos besoins
    };

    const response = await axios.post(
      "https://us-central1-reddd-6b0fd.cloudfunctions.net/api/testStripe",
      requestBody
    );

    setResponseData(response.data);
    window.location.href = response.data.url;
    setIsCheckOut(false);
  };

  let Total = 0;
  ProductInCart.forEach((dish) => {
    Total += dish.Production.SellingPrice * dish.QteInCart;
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxHeight: "500px",
        minHeight: "250px",
        overflowY: "auto",
        backgroundColor: "white",
        borderRadius: "10px",
        boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.1)",
        padding: "15px",
      }}
    >
      <div
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottom: "1px solid #f7f7f7",
        }}
      >
        <span style={{ marginRight: "15px" }}>
          <FaShoppingCart size={26} />
        </span>{" "}
        Panier
      </div>

      <div
        style={{
          padding: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => handleOpenModal()}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {SurPlaceOrEmporter ? (
            <PiForkKnifeFill size={20} style={{ marginRight: "5px" }} />
          ) : (
            <MdOutlineDirectionsRun size={20} style={{ marginRight: "5px" }} />
          )}
          <span>{`${selectedDay} à ${selectedTime}`}</span>
        </div>
        <div>
          <IoPencilSharp size={18} />
        </div>
      </div>
      <div
        style={{
          borderBottom: "1px solid #f7f7f7",
          borderTop: "1px solid #f7f7f7",
        }}
      >
        {ProductInCart.length > 0 ? (
          ProductInCart.map((dish, index) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div style={{ width: "50%" }}>{dish.Production.name}</div>
              <div
                style={{ width: "25%", display: "flex", alignItems: "center" }}
              >
                <CiCircleMinus
                  color={"purple"}
                  size={26}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setProductInCart((prev) => {
                      let newProductInCart = prev.map((item, idx) =>
                        idx === index
                          ? { ...item, QteInCart: item.QteInCart - 1 }
                          : item
                      );
                      newProductInCart = newProductInCart.filter(
                        (dish) => dish.QteInCart > 0
                      );
                      return newProductInCart;
                    })
                  }
                />

                <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                  {dish.QteInCart}
                </span>
                <FaCirclePlus
                  color={"purple"}
                  size={22}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setProductInCart((prev) =>
                      prev.map((item, idx) =>
                        idx === index
                          ? { ...item, QteInCart: item.QteInCart + 1 }
                          : item
                      )
                    )
                  }
                />
              </div>

              <div
                style={{
                  width: "22%",
                }}
              >
                {dish.Production.SellingPrice.toFixed(2)} €
              </div>
            </div>
          ))
        ) : (
          <div
            style={{
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaShoppingCart size={106} color="gray" />
            <span
              style={{
                color: "gray",
              }}
            >
              Panier vide
            </span>
          </div>
        )}
      </div>
      <div
        style={{
          alignSelf: "center",
          width: "100%",
        }}
      >
        {IsCheckOut ? (
          <div style={{ marginTop: "30px" }}>
            <CircularProgress size={24} color="secondary" />
          </div>
        ) : (
          <div
            style={{
              marginTop: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                fontSize: "16px",
                fontWeight: "bold",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div>Total :</div> <div>{Total.toFixed(2)} €</div>
            </div>
            <div
              style={{
                padding: "15px",
                border: "1px solid",
                borderRadius: "5px",
                cursor: "pointer",
                transition: "background-color 0.3s", // Transition de couleur de fond
                backgroundColor: "transparent", // Couleur de fond par défaut
                textAlign: "center", // Centrer le texte
                fontWeight: "bold", // Texte en gras
                fontSize: "16px", // Taille de police légèrement plus grande
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "pink")} // Changement de couleur de fond lorsque la souris passe dessus
              onMouseLeave={(e) =>
                (e.target.style.backgroundColor = "transparent")
              } // Réinitialiser la couleur de fond lorsque la souris quitte la zone
              onClick={() => HandleSendPaiement(ProductInCart)}
            >
              Valider mon panier
            </div>
          </div>
        )}
      </div>
      <ModalAvecState
        open={OpenModal}
        handleClose={handleCloseModal}
        TextActionButton={"Ajouter"}
        TitleModal={"Ajouter un nouveau restaurant"}
        setSurPlaceOrEmporter={setSurPlaceOrEmporter}
        SurPlaceOrEmporter={SurPlaceOrEmporter}
        setSelectedTime={setSelectedTime}
        setSelectedDay={setSelectedDay}
        selectedTime={selectedTime}
        selectedDay={selectedDay}
      />
    </div>
  );
};

export default CartDissplay;
