import {
  getFirestore,
  doc,
  collection,
  getDocs,
  getDoc,
  setDoc,
  query,
  orderBy,
  limit,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { app, firestore, storage } from "../firebaseConfig";
import { v4 as uuidv4 } from "uuid";
import { m } from "framer-motion";
import { ca, el } from "date-fns/locale";

export async function calculCoutProductionDish(ListeDishObject) {
  try {
    let AllNewListeDishObject = [];

    for (const Dish of ListeDishObject) {
      let CoutHT = 0;

      // Créer un tableau de promesses pour chaque appel asynchrone
      const promises = Dish.Production.PreparationIntermediaire.map(
        async (PrepUSed) => {
          const PrepDocRef = doc(firestore, "preparations", PrepUSed.IDPrep);
          const PrepSnapshot = await getDoc(PrepDocRef);
          if (PrepSnapshot.exists()) {
            const PrepData = PrepSnapshot.data();
            CoutHT += PrepData.PrixHTParUniteDeMesure * PrepUSed.Qte;
          } else {
            console.log("IngredientSnapshot does not exist");
            throw new Error("IngredientSnapshot does not exist");
          }
        }
      );

      // Attendre que toutes les promesses se terminent
      await Promise.all(promises);

      AllNewListeDishObject.push({
        ...Dish,
        CoutRevientHTVBB: parseFloat(CoutHT.toFixed(6)),
      });
    }

    return AllNewListeDishObject;
  } catch (error) {
    console.error(error.message);
    // Gérez l'erreur ici, par exemple, affichez un message à l'utilisateur
    return; // Sortir de la fonction si une erreur se produit
  }
}

export async function getAllDish() {
  try {
    const DishdocRef = collection(firestore, "dishes");
    const querySnapshot = await getDocs(query(DishdocRef, limit(150))); // Limiter à 50 résultats
    const data = querySnapshot.docs.map((doc) => doc.data());
    const AllDishPrice = await calculCoutProductionDish(data);

    return AllDishPrice;
  } catch (e) {
    console.log(e);
  }
}

export async function CreateModuleDeCommande(ListeDish) {
  try {
    let ListeDishInModule = ListeDish.map((Dish) => Dish.Production.IDPepers);
    const IDModule = uuidv4();
    const ModuleDeCommandedocRef = doc(
      firestore,
      `RestaurantSainteFoyModuleDeCommande`,
      IDModule
    );
    await setDoc(ModuleDeCommandedocRef, {
      ListeDishInModule,
    });

    return IDModule;
  } catch (e) {
    console.log(e);
  }
}
export async function getModuleCommandeData(ModuleID) {
  try {
    const ModuleDeCommandedocRef = doc(
      firestore,
      `RestaurantSainteFoyModuleDeCommande`,
      ModuleID
    );
    const querySnapshot = await getDoc(ModuleDeCommandedocRef);

    const data = querySnapshot.data();

    let ListeDishData = [];
    for (const DishID of data.ListeDishInModule) {
      const DishDocRef = doc(firestore, "dishes", DishID);
      const DishSnapshot = await getDoc(DishDocRef);
      if (DishSnapshot.exists()) {
        let DishData = DishSnapshot.data();
        ListeDishData.push({ ...DishData });
      } else {
        console.log("DishSnapshot does not exist");
      }
    }
    // On trie la liste en fonction des categories
    const CategorieDishdocRef = doc(firestore, "Production", "CategorieDish");
    const CategorieDishSnapshot = await getDoc(CategorieDishdocRef);
    const CategorieDishData = CategorieDishSnapshot.data().ListeCategorie;
    let ListeDishDataTrie = {};

    for (const Categorie of CategorieDishData) {
      const categorieDish = ListeDishData.filter(
        (Dish) => Dish.CategorieDish === Categorie.IDPepers
      );
      if (categorieDish.length > 0) {
        ListeDishDataTrie = {
          ...ListeDishDataTrie,
          [Categorie.name]: categorieDish,
        };
      }
    }
    return ListeDishDataTrie;
  } catch (e) {
    console.log(e);
  }
}
export async function CreationCategorieDish() {
  try {
    const CategorieDishdocRef = doc(firestore, "Production", "CategorieDish");
    const ListeCategorie = [
      {
        name: "Menu",
        IDPepers: uuidv4(),
      },
      {
        name: "Plat Principal",
        IDPepers: uuidv4(),
      },
      {
        name: "Acompagnement",
        IDPepers: uuidv4(),
      },
      {
        name: "Entrée",
        IDPepers: uuidv4(),
      },
      {
        name: "Dessert",
        IDPepers: uuidv4(),
      },
      {
        name: "Boisson",
        IDPepers: uuidv4(),
      },
    ];
    await setDoc(CategorieDishdocRef, {
      ListeCategorie,
    });
  } catch (e) {
    console.log(e);
  }
}

export function generateTimeSlots() {
  const timeSlots = [];
  let currentDate = new Date();
  let currentHour = currentDate.getHours();
  let currentMinute = currentDate.getMinutes();

  if (
    currentHour >= 14 &&
    currentMinute >= 30 &&
    currentHour <= 18 &&
    currentMinute <= 30
  ) {
    // Si l'heure actuelle est comprise entre 14h30 et 18h30 ou entre 00h00 et 11h30
    currentDate.setHours(18); // Définir l'heure sur 18
    currentDate.setMinutes(30); // Définir les minutes sur 15
  }

  if (
    currentHour >= 0 &&
    currentMinute >= 0 &&
    currentHour <= 11 &&
    currentMinute <= 30
  ) {
    // Si l'heure actuelle est comprise entre 14h30 et 18h30 ou entre 00h00 et 11h30
    currentDate.setHours(11); // Définir l'heure sur 18
    currentDate.setMinutes(30); // Définir les minutes sur 15
  }

  currentHour = currentDate.getHours();
  currentMinute = currentDate.getMinutes();

  const currentTimeInMinutes = currentHour * 60 + currentMinute;

  const endTimeInMinutes = 22 * 60 + 30;
  let nextTimeInMinutes = Math.ceil(currentTimeInMinutes / 15) * 15; // Round up to the next 15-minute interval

  while (nextTimeInMinutes <= endTimeInMinutes) {
    const hour = Math.floor(nextTimeInMinutes / 60);
    const minute = nextTimeInMinutes % 60;
    const formattedHour = hour.toString().padStart(2, "0");
    const formattedMinute = minute.toString().padStart(2, "0");
    const timeString = `${formattedHour}:${formattedMinute}`;
    timeSlots.push(timeString);
    nextTimeInMinutes += 15;
  }

  return timeSlots;
}
