import React, { useState, useEffect } from "react";
import CartDissplay from "./CartDissplay/CartDissplay";
import "./MainAreaProductAndCart.css";

const MainAreaProductAndCart = ({ Data, ModuleID }) => {
  const [ProductInCart, setProductInCart] = useState([]);
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "5fr 3fr",
        maxHeight: "600px",
        maxWidth: "1100px",
        overflowY: "auto",
        paddingLeft: "15vw", // Marge à gauche proportionnelle à la largeur de la fenêtre
        paddingRight: "15vw",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        {Data &&
          Object.keys(Data).map((categorie) => (
            <div key={categorie} className="categorie">
              <div
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                {categorie}
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {Data[categorie].map((dish) => (
                  <div
                    className="dishCard"
                    onClick={() => {
                      setProductInCart((prev) => {
                        const listIDDish = prev.map(
                          (dish) => dish.Production.IDPepers
                        );
                        if (!listIDDish.includes(dish.Production.IDPepers)) {
                          return [...prev, { ...dish, QteInCart: 1 }];
                        } else {
                          return [...prev];
                        }
                      });
                    }}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${dish.Production.img})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        width: "100%",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "10px",
                      }}
                    >
                      <div style={{ wordWrap: "break-word" }}>
                        {dish.Production.name}
                      </div>
                      <div>
                        <span className="SellingPriceDish">
                          {dish.Production.SellingPrice} €
                        </span>
                      </div>
                    </div>
                  </div>
                ))}{" "}
              </div>
            </div>
          ))}
      </div>

      <CartDissplay
        ProductInCart={ProductInCart}
        ModuleID={ModuleID}
        setProductInCart={setProductInCart}
      />
    </div>
  );
};

export default MainAreaProductAndCart;
