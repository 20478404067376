import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ModuleDeCommande from "./Components/ModuleDeCommande/ModuleDeCommande";
import CreationModule from "./Components/CreationModule/CreationModule";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CreationModule />} />
        <Route
          path="/ModuleCommande/:ModuleID"
          element={<ModuleDeCommande />}
        />
      </Routes>
    </Router>
  );
}

export default App;
