import React, { useState, useEffect } from "react";
import { getAllDish } from "../../../../../../Data/firebaseInteractionMarketing";
import "./DisplayProduct.css";
import { useSpring, animated } from "react-spring";

const DisplayProduct = ({ setSelectedDish }) => {
  const [ProductSelected, setProductSelected] = useState([
    "Pizza",
    "Pasta",
    "Dessert",
    "Boisson",
  ]);
  const [Dishes, setDishes] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const AllDish = await getAllDish();
      setDishes(AllDish);
    }
    fetchData();
  }, []);

  return (
    <div className="DisplayProduct">
      {Dishes &&
        Dishes.map((prod) => (
          <div
            className="VignetteProduit"
            onClick={() => {
              setSelectedDish((prev) => {
                const listIDDish = prev.map((dish) => dish.Production.IDPepers);
                if (!listIDDish.includes(prod.Production.IDPepers)) {
                  return [...prev, { ...prod }];
                } else {
                  return [...prev];
                }
              });
            }}
          >
            <div>
              <img className="imgProd" src={prod.Production.img}></img>
            </div>
            <div className="Description">
              <div>{prod.Production.name}</div>
              <div>{(prod.CoutRevientHTVBB * 2.5).toFixed(2)} € HT</div>
              <div>
                {" "}
                Prix vente TTC : {prod.Production.SellingPrice.toFixed(2)} € TTC
              </div>
              <div>
                Prix vente HT :{" "}
                {(prod.Production.SellingPrice / 1.1).toFixed(2)}€ HT
              </div>
              <div>
                Marge :{" "}
                {(
                  (prod.Production.SellingPrice / 1.1 -
                    prod.CoutRevientHTVBB * 2.5) /
                  prod.Production.SellingPrice
                ).toFixed(2)}
                %
              </div>
              <div> </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default DisplayProduct;
