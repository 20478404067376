import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <div
      style={{
        minWidth: "800px",
        borderBottom: "1px solid #ccc",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        paddingLeft: "15vw", // Marge à gauche proportionnelle à la largeur de la fenêtre
        paddingRight: "15vw",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
      }}
    >
      <img
        src="/img/KitchnClikLogo.png"
        style={{ maxWidth: "200px", padding: "15px" }}
      />
      <div className="Help">
        <div>Besoin d'aide ?</div>
      </div>
      <a
        className="CustomLink"
        href="https://calendly.com/vbbhouse/30min"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="Button">Prendre rendez vous</div>
      </a>
    </div>
  );
};

export default Header;
