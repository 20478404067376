import React, { useState } from "react";

const DisplayCategoriesModuleCommande = ({ CategorieFood }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        paddingLeft: "15vw", // Marge à gauche proportionnelle à la largeur de la fenêtre
        paddingRight: "15vw",
      }}
    >
      {CategorieFood.map((cat) => (
        <div
          style={{
            paddingRight: "50px",
            paddingLeft: "50px",
            paddingTop: "5px",
            paddingBottom: "5px",
            borderRadius: "5px",
            backgroundColor: "purple",
            color: "white",
            margin: "15px",
          }}
        >
          {cat}
        </div>
      ))}
    </div>
  );
};

export default DisplayCategoriesModuleCommande;
