import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getModuleCommandeData } from "../../Data/firebaseInteractionMarketing";
import HeaderModuleDeCommande from "./HeaderModuleDeCommande/HeaderModuleDeCommande";
import AdresseInfo from "./AdresseInfo/AdresseInfo";
import DisplayCategoriesModuleCommande from "./DisplayCategoriesModuleCommande/DisplayCategoriesModuleCommande";
import MainAreaProductAndCart from "./MainAreaProductAndCart/MainAreaProductAndCart";

const ModuleDeCommande = () => {
  let { ModuleID } = useParams();
  const [DishModule, setDishModule] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const Data = await getModuleCommandeData(ModuleID);
      setDishModule(Data);
    }
    fetchData();
  }, []);

  return (
    <div style={{ backgroundColor: "#f7f7f7", height: "100vh" }}>
      <div
        style={{
          display: "grid",
          gridTemplateRows: "auto auto auto auto",
          rowGap: "10px",
        }}
      >
        <div style={{ backgroundColor: "white" }}>
          <HeaderModuleDeCommande />
          <AdresseInfo />
          {DishModule && (
            <DisplayCategoriesModuleCommande
              CategorieFood={Object.keys(DishModule)}
            />
          )}
        </div>
        {DishModule && (
          <MainAreaProductAndCart Data={DishModule} ModuleID={ModuleID} />
        )}
      </div>
    </div>
  );
};

export default ModuleDeCommande;
